<template>
  <div class="container">
    <My-Breadcrumb :breadcrumbs="breadcrumbs" />
    <div class="mainBox">
      <div class="control_box">
        <Search-bar :dateTime="dateTime" @search="search" @reset="search">
          <template v-slot:expand-button>
            <a-button type="primary" icon="plus-square" style="margin-left: 10px" @click="showPatchAdd">批量发布</a-button>
          </template>
        </Search-bar>
      </div>

      <!-- 菜单类型 -->
      <div class="show_left">
        <a-page-header style="padding-left: 0; padding-right: 0" title="菜品类型"></a-page-header>
        <a-table :columns="columnsLeft" :data-source="menuTypeData" :rowKey="(menuTypeData) => menuTypeData.id"
          :pagination="false">
          <template slot="id" slot-scope="text, record">
              <div :class="record.id == queryParam.type_id?'active-menu-td':'menu-td'" @click="searchByType(record.id)">
                <span :class="record.id == queryParam.type_id?'active':'menu-slot-btn'" >{{record.title}}</span>
              </div>
          </template>
        </a-table>
      </div>

      <!-- 内容区域 -->
      <div class="show_right">
        <a-tabs v-model="activeMealTime" @change="changeMealTime">
          <a-tab-pane v-for="item in mealTimes" :key="item.id" :tab="item.title">
            <a-table :columns="columns" :data-source="tableData" :loading="loading"
              :row-key="(tableData) => tableData.id" :pagination="false">
              <template slot="link_url" slot-scope="record">
                <!-- <img style="width: 50px; heigth: 50px" :src="record.link_url" alt /> -->
                <a-avatar shape="square" size="large" :src="record.link_url"/>
              </template>
              <template slot="action" slot-scope="record">
                <a-button type="link" @click="changeStock(record)" >编辑</a-button>
                <a-divider type="vertical" />
                <a-button type="link" @click="deleteFn(record)">取消发布</a-button>
              </template>
            </a-table>
            <a-button style="float: right;margin-top: 10px;" icon="plus" type="primary" @click="showAddMenu">发布菜品
            </a-button>
          </a-tab-pane>
        </a-tabs>
      </div>
    </div>

    <!-- 修改库存 -->
    <a-modal title="修改库存" v-model="stockVisible" @ok="handleUpdateStock">
      <a-form-model :model="stockParam" :label-col="{ span: 3 }" :wrapper-col="{ span: 21 }">
        <a-form-model-item label="库存">
          <a-input v-model="stockParam.stock" placeholder="库存" />
        </a-form-model-item>
      </a-form-model>
    </a-modal>

    <!-- 选择菜品弹窗 -->
    <a-modal v-model="menuVisible" @ok="handleUpdateMenus" :width="700">
      <template slot="title">
        选择菜品
        <a-input v-model="searchMeal" placeholder="可输入菜品名称进行搜索" style="width:200px;margin-left:50px;"></a-input>
        <a-button @click="searchMealFn" type="primary" style="margin-left:10px;">搜索</a-button>
        <a-button @click="searchMealFnReset" style="margin-left:10px;">刷新</a-button>
      </template>
      <a-form-model :model="addDailyMenuParam" :label-col="{ span: 3 }" :wrapper-col="{ span: 21 }">
        <p v-if="canAddMenus.length == 0">所选类别下暂无菜品，请前往菜品库添加</p>
        <div v-else style="width: 100%; height: 600px; overflow: auto;">
          <a-form-model-item>
            <template slot="label">
              <a-checkbox :indeterminate="indeterMenu" :checked="checkAllMenu" @change="onCheckAllMenu">全选</a-checkbox>
            </template>
            <a-checkbox-group v-model="addDailyMenuParam.menu_ids" style="width:100%;">
              <a-row>
                <a-col v-for="(item, index) in canAddMenus" :key="index" span="23">
                  <a-card style="margin-bottom: 10px">
                    <template slot="title">
                      <!-- <a-avatar shape="square" :size="65" slot="avatar" :src="item.link_url" /> -->

                      <a-avatar shape="square" size="large" icon="user" :src="item.link_url"/>
                      {{ item.title }}
                    </template>
                    <template slot="extra">
                      <a-checkbox :value="item.id"></a-checkbox>
                    </template>
                    <a-card-meta :description="`价格：${item.price}，预设库存：${item.stock}`"></a-card-meta>
                  </a-card>
                </a-col>
              </a-row>
            </a-checkbox-group>
          </a-form-model-item>
          <scroll-loader :loader-method="changePage" :loader-disable="disable"></scroll-loader>
          <p v-show="disable && canAddMenus.length != 0" style="text-align:center;">没有更多数据了~</p>
        </div>
      </a-form-model>
    </a-modal>

    <!-- 批量添加 -->
    <a-modal title="批量发布" v-model="patchAddVisible" @ok="handlePatchAdd" okText="提交" :width="900">
      <!-- 时段选择 -->
      <h3>时段</h3>
      <p>上架对应时段</p>
      <a-radio-group v-model="activeTime" @change="changeTime">
        <a-radio v-for="item in mealTimes" :key="item.id" :value="item.id" :disabled="removeClick">
          {{item.title}}
        </a-radio>
      </a-radio-group>

      <!-- 日期选择 -->
      <h3 class="mt_30">日期</h3>
      <p>上架对应日期，支持单选，多选</p>
      <Calendar style="margin:0 auto;" 
                @myClickDay="myClickDay" 
                @myCheckDetail="myCheckDetail"
                @preMonth="preMonth" 
                @nextMonth="nextMonth"
                :calendarOption="calendarOption"
                :removeClick="removeClick"
      />
  
      <!-- 菜品选择 -->
      <div v-show="showMealChoose">
        <div class="meal-title-wrapper">
          <h3>菜品</h3>
          <a-input placeholder="请输入菜品名称进行搜索"
                          style="width: 200px"
                          @change="onSearch" 
                          v-model="searchMeal"
          />
        </div>
        <p>需上架的菜品，支持单选，多选</p>
        <a-checkbox :indeterminate="indeterminateMeals" :checked="checkAllMeals" @change="onCheckAllMealsChange" class="mb_10">
          全选
        </a-checkbox>
        <br />
        <a-checkbox-group :value="checkedMeals" @change="onChangeMeal" style="width:100%;">
          <a-row>
            <a-col v-for="item in meals" :key="item.id" :span="4">
              <a-checkbox :value="item.id" class="text_limit">
                <span :title="item.title">{{item.title}}</span>
              </a-checkbox>
            </a-col>
          </a-row>
        </a-checkbox-group>
      </div>

      <!-- 添加/保存菜品 -->
      <div class="control-wrapper">
        <div class="add_meal" v-show="chooseDays.length > 0 && !showMealChoose" @click="createMeal">
          发布菜品
        </div>
        <div class="back-btn" v-show="showMealChoose" @click="cancelOpt">
          取消操作
        </div>
        <div class="save_meal" v-show="showMealChoose" @click="saveMeal">
          保存菜品
        </div>
      </div>
    </a-modal>

    <!-- 查看所选日期菜品 -->
    <a-modal :title="checkDetailTitle" v-model="checkDetailVisible" @ok="checkDetailVisible = false" :footer="null">
      <div v-if="checkDetailMealArr && checkDetailMealArr.length > 0">
        <a-row v-for="(item,index) in checkDetailMealArr" :key="item.id" class="meal_row">
          <a-col :span="20" class="meal_title">
            {{item.title}}
          </a-col>
          <a-col :span="4">
            <a-button @click="deleteMeal(index)" class="remove_meal">移除</a-button>
          </a-col>
        </a-row>
      </div>
    </a-modal>
  </div>
</template>

<script>
//引入接口api
import {
  dailyIndex,
  dailyCreate,
  dailyList,
  dailyDelete,
  dailyUpdate,
  mealtimeListWithout,
  menuTypesList,
  menuIndex,
  dailyBatchUpdate
} from "@/request/api";
//引入封装的组件
import MyBreadcrumb from "@/components/common/MyBreadcrumb.vue";
import SearchBar from "@/components/common/SearchBar.vue";
import Calendar from "@/components/EveryDayMenu/Calendar.vue";
//引入工具函数
import util from "@/utils/util";
function getBase64 (file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
}
import moment from "moment";

export default {
  //import引入的组件需要注入到对象中才能使用
  components: {
    MyBreadcrumb,
    SearchBar,
    Calendar
  },
  data () {
    return {
      //传给MyBreadcrumb的参数
      breadcrumbs: [
        {
          key: 0,
          title: "用餐管理",
        },
        {
          key: 1,
          title: "菜品发布",
        },
      ],
      selects: [
        {
          model: "meal_id",
          placeholder: "全部时段",
          width: 150,
          options: [
            {
              key: "",
              value: "全部时段",
            },
          ],
        },
        {
          model: "type_id",
          placeholder: "全部类别",
          width: 150,
          options: [
            {
              key: "",
              value: "全部类别",
            },
          ],
        },
      ],
      dateTime: {
        show: true,
      },
      //****菜谱列表相关参数
      nowDate: moment(this.getCurrentData(), "YYYY-MM-DD"),
      queryParam: {
        repast_date: "",
        meal_id: "",
        type_id: 0,
        no_paging: 1,
      },
      tableData: [], //从接口请求回来并在js部分整理好的表格数据
      loading: false, //table加载状态
      columns: [
        // 列配置
        {
          title: "菜品图片",
          key: "link_url",
          scopedSlots: { customRender: "link_url" },
        },
        {
          title: "菜品名称",
          key: "title",
          width: 220,
          ellipsis: true,
          dataIndex: "title",
        },
        {
          title: "价格",
          key: "price",
          dataIndex: "price",
        },
        {
          title: "库存",
          key: "stock",
          dataIndex: "stock",
        },
        {
          title: "操作",
          key: "action",
          scopedSlots: { customRender: "action" },
        },
      ],

      //添加菜品发布参数
      addDailyMenuParam: {
        repast_date: "",
        menu_ids: [],
        meal_id: "",
      },
      menuVisible: false,
      indeterMenu: false, //控制菜品全选样式
      checkAllMenu: false, //菜品全选标识
      canAddMenus: [],

      //修改库存
      stockVisible: false,
      stockParam: {
        stock: "",
      },
      changeStockId: "",
      mealTimes: [],
      columnsDetail: [],
      menuTypeData: [],
      columnsLeft: [
        {
          title: "菜品类型",
          key: "title",
          dataIndex: "title",
          scopedSlots: { customRender: "id" },
        },
        // {
        //   title: "操作",
        //   key: "action",
        //   scopedSlots: { customRender: "action" },
        // },
      ],
      searchMeal: "",
      canAddMenusOld: [],
      patchAddVisible:false,
      activeTime:'',//当前选中的
      indeterminateMeals:false,
      checkAllMeals:false,
      meals:[],
      checkedMeals:[],
      checkDetailVisible:false,
      checkDetailTitle:'',
      checkDetailMealArr:[],
      chooseDays:[],
      showMealChoose:false,
      updateData:[],
      patchCreateData:[],
      calendarOption:{},
      removeClick:false,
      searchMeal: '',
      originMeals: [],
      current: 1,
      disable: false,
      activeMealTime: null
    };
  },
  //监听属性 类似于data概念
  computed: {},
  //监控data中的数据变化
  watch: {},
  //方法集合
  methods: {
    moment,
    getCurrentData () {
      return new Date().toLocaleDateString();
    },
    // 刷新
    search (val) {
      this.queryParam.repast_date = val.currentDate;
      this.getTableData();
    },

    select (val) {
      this.queryParam.type_id = val.type_id;
      this.queryParam.meal_id = val.meal_id;
    },

    changeDate (val) {
      this.queryParam.repast_date = val;
    },

    //获取表格数据
    getTableData () {
      this.loading = true;
      dailyIndex(this.queryParam).then((res) => {
        let resultData = res.data;
        //空列表判断
        if (resultData == 0) {
          this.loading = false;
          this.tableData = [];
          return;
        }
        //列表数据处理
        this.tableData = resultData.map((item) => {
          item.title = item.menu ? item.menu.title : "无";
          item.price = item.menu ? item.menu.price : 0;
          item.link_url = item.menu ? item.menu.link_url : "";
          return item;
        });
        this.loading = false;
      });
    },

    //显示添加菜品弹窗
    showAddMenu () {
      this.addDailyMenuParam.menu_ids = [];
      Object.assign(this,{
        menuVisible: true,
        searchMeal: '',
        canAddMenusOld: [],
        current: 1,
        disable: false,
        canAddMenus: []
      })
      this.getDailyList();
    },

    //获取可选的每日菜品
    getDailyList () {
      let param = {
        type_id: this.queryParam.type_id,
        daily_date: this.queryParam.repast_date,
        page: this.current,
        title: this.searchMeal
      };
      dailyList(param).then((res) => {
        this.indeterMenu = false;
        this.checkAllMenu = false;
        let alert = res.data.data,
          table = this.tableData;
        for (let i in alert) {
          for (let j in table) {
            if (alert[i].id == table[j].menu_id) {
              alert.splice(i, 1);
            }
          }
        }
        if(alert.length == 0){
          this.disable = true
          return
        }
        alert.forEach(i => {
          this.canAddMenus.push(i)
        })
      });
    },

    //全选菜品
    onCheckAllMenu (e) {
      this.addDailyMenuParam.menu_ids = e.target.checked
        ? this.canAddMenus.map((item) => {
          return (item = item.id);
        })
        : [];
      this.indeterMenu = false;
      this.checkAllMenu = e.target.checked;
    },

    //提交所选的菜品
    handleUpdateMenus: util.DebounceBy(function () {
      this.addDailyMenuParam.repast_date = this.queryParam.repast_date
        ? this.queryParam.repast_date
        : moment(this.getCurrentData()).format("YYYY-MM-DD");
      this.addDailyMenuParam.meal_id = this.queryParam.meal_id;
      dailyCreate(this.addDailyMenuParam)
        .then((res) => { })
        .catch((err) => {
          if (err.status == 201) {
            this.$message.success(err.data.msg);
            this.menuVisible = false;
            this.getTableData();
          }else{
            this.$message.error(err.data.msg);
          }
        });
    }, 3000),

    //显示修改库存
    changeStock (record) {
      this.stockVisible = true;
      this.stockParam.stock = record.stock;
      this.changeStockId = record.id;
    },

    //提交更改库存
    handleUpdateStock: util.DebounceBy(function () {
      if (this.stockParam.stock === "") {
        this.$message.info("库存不能为空");
        return;
      }
      dailyUpdate(this.changeStockId, this.stockParam)
        .then((res) => {
          this.$message.success("修改成功");
          this.stockVisible = false;
          this.getTableData();
        })
        .catch((err) => { });
    }, 3000),

    //删除菜品
    deleteFn (record) {
      let id = record.id,
        title = record.title;
      this.$confirm({
        title: `确定移除菜品${title}？`,
        onOk: () => {
          dailyDelete(id)
            .then((res) => { })
            .catch((err) => {
              if (err.status == 204) {
                this.$message.success("移除成功");
                this.getTableData();
              } else {
                this.$message.error("移除失败");
              }
            });
        },
      });
    },

    //获取菜品分类
    getMenuType () {
      menuTypesList({ no_paging: 1 }).then((res) => {
        res.data.sort((a, b) => {
          if (a.is_fixed == 1) {
            return -1;
          }
        });
        //增加'查询全部'固定选项
        let obj = {
          id:0,
          title:'全部',
          is_fixed:0
        }
        this.menuTypeData = res.data;
        this.menuTypeData.unshift(obj);
        // res.data.map((item) => {
        //   if (item.is_fixed == 1) {
        //     this.queryParam.type_id = item.id;
        //   }
        // });
        this.getTableData();
      });
    },
    searchByType (id) {
      console.log('record', id);
      this.queryParam.type_id = id;
      this.getTableData();
    },

    //获取就餐时段
    getMealtimeList () {
      mealtimeListWithout({ no_paging: 1 }).then((res) => {
        this.mealTimes = res.data;
        this.activeTime = res.data[0].id;
        res.data.map(item => {
          this.patchCreateData.push({
            mealTimeId:item.id,
            mealTimeTitle:item.title,
            calendarData:[]
          })
        })
        this.queryParam.meal_id = this.mealTimes[0].id;
        this.activeMealTime = this.mealTimes[0].id
        this.getMenuType();
      });
    },
    changeMealTime (val) {
      this.queryParam.meal_id = val;
      this.getTableData();
    },
    async searchMealFn () {
      this.disable = false
      this.current = 1
      this.canAddMenus = []
      await this.getDailyList()
    },
    async searchMealFnReset () {
      this.searchMeal = '';
      this.current = 1
      this.disable = false
      this.canAddMenus = []
      this.addDailyMenuParam.menu_ids = [];
      await this.getDailyList()
    },
    //模糊查询1:利用字符串的indexOf方法
    searchByIndexOf (keyWord, list) {
      if (!(list instanceof Array)) {
        return;
      }
      var len = list.length;
      var arr = [];
      for (let i = 0; i < len; i++) {
        //如果字符串中不包含目标字符会返回-1
        if (list[i].title.indexOf(keyWord) >= 0) {
          arr.push(list[i]);
        }
      }
      return arr;
    },

    //获取全部菜品
    getAllMenu(){
      menuIndex({no_paging:1}).then(res => {
        this.meals = res.data;
        this.originMeals = res.data;
      }).catch(err => {
        console.log(err)
      })
    },

    // 打开批量添加框
    showPatchAdd(){
      this.patchAddVisible = true
    },

    //提交批量添加
    handlePatchAdd(){
      let formated = this.formatToParam(this.patchCreateData);
      if(formated.data.length == 0){
        this.$message.info('请添加上架菜品')
        return
      }
      this.$confirm({
        title:'确定批量发布所选菜品？',
        onOk:() => {
          dailyBatchUpdate(formated).then(res => {}).catch(err => {
            if(err.status == 201){
              this.$message.success('添加成功')
              Object.assign(this,{
                activeTime: '',
                showMealChoose: false,
                indeterminateMeals: false,
                checkAllMeals: false,
                checkedMeals:[],
                chooseDays:[],
                showMealChoose:false,
                updateData:[],
                patchCreateData:[],
                calendarOption: {},
                removeClick:false,
                patchAddVisible: false
              })
              this.activeMealTime = this.mealTimes[0].id
              this.getTableData();
              this.getMealtimeList();
            }else if(err.status == 300){
              // 时段不存在
              let missingTimeId = err.data.msg * 1,
                  missingTimeTitle = this.mealTimes[this.findIndexById(missingTimeId, this.mealTimes)].title;
              
              this.$info({
                title:`不存在时段：${missingTimeTitle} ，将自动为您删除本次该时段下添加的菜品`
              })
              this.patchCreateData.splice(this.findIndexById(missingTimeId,this.patchCreateData,'mealTimeId'),1)
              this.activeTime = this.mealTimes[0].id
              this.changeTime()
              this.mealTimes.splice(this.findIndexById(missingTimeId,this.mealTimes),1)
            }else if(err.status == 400){
              if(err.data.error_code == 40002){
                // 菜品不存在
                let missingMenuId = err.data.msg * 1,
                missingMenuTitle = this.meals[this.findIndexById(missingMenuId,this.meals)].title;
                this.$info({
                  title: `不存在菜品：${missingMenuTitle} ，将自动为您删除本次操作中添加的该菜品`
                })
                
                for (let i = 0; i < this.patchCreateData.length; i++) {
                  let element = this.patchCreateData[i],calendarData = element.calendarData;
                  if(calendarData.length > 0){
                    for (let j = 0; j < calendarData.length; j++) {
                      let element2 = calendarData[j],daysInMonth = element2.daysInMonth;
                      if(daysInMonth.length > 0){
                        for (let k = 0; k < daysInMonth.length; k++) {
                          let element3 = daysInMonth[k],mealArr = element3.mealArr;
                          if(mealArr.length > 0){
                            for (let l = 0; l < mealArr.length; l++) {
                              let meal = mealArr[l],mealId = meal.id,missingMealIndex = this.findIndexById(missingMenuId,mealArr);
                              if(missingMealIndex != -1){
                                mealArr.splice(missingMealIndex,1)
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
                this.meals.splice(this.findIndexById(missingMenuId,this.meals),1)
              }
            }else{
              // 其他情况暂不处理
              console.log(err)
            }
          })
        }
      })
      
    },

    findIndexById(id,arr,itemKey){
      let index = arr.findIndex(item => {
        if(itemKey){
          return item[itemKey] == id
        }else{
          return item.id == id
        }
      })
      return index
    },

    //把数据整理成接口所需格式
    formatToParam(needFormat){
      let copyDataDeep = [...needFormat],//对传递进来的数据进行深拷贝
          meal_ids = [],//要提交的时段id数组
          days = [],//有添加菜品的日期数组
          data = [];//要提交的data数组
      for (let i = 0; i < copyDataDeep.length; i++) {
        let mealObj = copyDataDeep[i],
            calendarData = copyDataDeep[i].calendarData;
        for (let j = 0; j < calendarData.length; j++) {
          let daysInMonth = calendarData[j].daysInMonth;
          for (let k = 0; k < daysInMonth.length; k++) {
            let mealArr = daysInMonth[k].mealArr;
            if(mealArr.length != 0) {
              meal_ids.push(mealObj.mealTimeId)
              days.push(daysInMonth[k])
            }
          }
        }
      }
      meal_ids = [...new Set(meal_ids)]//得到所有有添加菜品的时段

      let daysStrArr = []; 
      for (let i = 0; i < days.length; i++) {
        let day = days[i].dateStr;
        daysStrArr.push(day)
      }
      daysStrArr = [...new Set(daysStrArr)].sort((a, b) => { return moment(a).diff(b) })//去重并根据日期排序的日期字符串数组
      let counts = daysStrArr.length; //推到data数组里的次数
      
      for (let i = 0; i < daysStrArr.length; i++) {
        let daysStr = daysStrArr[i],
            dataItemObj = {
              repast_date: daysStr,
              meal_array:[]
            };
        for (let j = 0; j < copyDataDeep.length; j++) {
          let copyDataDeepItem = copyDataDeep[j],
              calendarData = copyDataDeepItem.calendarData;
          for (let k = 0; k < calendarData.length; k++) {
            let daysInMonth = calendarData[k].daysInMonth;
            for (let l = 0; l < daysInMonth.length; l++) {
              let dateStr = daysInMonth[l].dateStr,
                  mealArr = daysInMonth[l].mealArr;
              if(dateStr == daysStr && mealArr.length > 0){
                if(dataItemObj.meal_array.length > 0){
                  for (let m = 0; m < dataItemObj.meal_array.length; m++) {
                    let el = dataItemObj.meal_array[m];
                    if(el.menu_ids && el.menu_ids.length > 0){
                      dataItemObj.meal_array.splice(m, 1);
                      m--;
                    }
                  }
                }
                dataItemObj.meal_array.push({
                  meal_id: copyDataDeepItem.mealTimeId,
                  menu_ids: [...new Set(mealArr.map(item => { return item.id}))]
                })
              }
            }
          }
        }

        data.push(dataItemObj)
      }

      return {
        meal_ids,
        data
      }
    },

    //切换时段
    changeTime(){
      this.updateData = []
      let index = this.patchCreateData.findIndex(item => {
        return item.mealTimeId == this.activeTime
      })
      this.calendarOption = this.patchCreateData[index]
    },

    //选择菜品
    onChangeMeal(checkedList){
      let newCheckedMeals = this.checkedMeals.concat(checkedList);
      this.checkedMeals = [...new Set(newCheckedMeals)];
      this.indeterminateMeals = !!checkedList.length && checkedList.length < this.meals.length;
      this.checkAllMeals = checkedList.length === this.meals.length;
    },

    //切换全选
    onCheckAllMealsChange(e) {
      let mealsValues = [];
      this.meals.forEach(item => {
        mealsValues.push(item.id)
      })
      //Object.assign(target,source) 方法用于将所有可枚举属性的值从一个或多个源对象分配到目标对象。它将返回目标对象
      Object.assign(this, {
        checkedMeals: e.target.checked ? mealsValues : [],
        indeterminateMeals: false,
        checkAllMeals: e.target.checked,
      });
    },

    //自定义日历组件的点击事件
    myClickDay(val){
      this.chooseDays = val;
    },

    // 合并数组并去重
    setArrAndConcat(arr1, arr2){
      let result = [];
      result = arr1.concat(arr2);
      return [...new Set(result)]
    },

    // 保存菜品
    saveMeal(){
      if(this.checkedMeals.length == 0){
        this.$message.info('请选择菜品')
        return
      }
      // checkedMeals：已勾选的菜品
      let checkedMeals = [];
      for (let i = 0; i < this.checkedMeals.length; i++) {
        let id = this.checkedMeals[i];
        for (let j = 0; j < this.meals.length; j++) {
          let meal = this.meals[j];
          if(meal.id == id){
            checkedMeals.push(meal)
          }
        }
      }

      this.chooseDays.map(item => {
        item.mealArr = item.mealArr.length == 0 ? [...checkedMeals] : this.setArrAndConcat(item.mealArr, checkedMeals),
        item.choose = false,
        item.activeTime = this.activeTime
      })

      if(this.updateData.length == 0){
        this.updateData = this.chooseDays
      }else{
        this.chooseDays.map(item => {
          this.updateData.push(item)
        })
      }

      let indexPatchCreateData = this.patchCreateData.findIndex(item => {return item.mealTimeId == this.activeTime});
      if(indexPatchCreateData != -1){
        if(this.patchCreateData[indexPatchCreateData].calendarData.length == 0){
          this.patchCreateData[indexPatchCreateData].calendarData.push({
            month:this.updateData[0].month,
            daysInMonth:this.updateData
          })
        }else{
          let index = this.patchCreateData[indexPatchCreateData].calendarData.findIndex(item => {return item.month == this.updateData[0].month});
          if(index == -1){
            this.patchCreateData[indexPatchCreateData].calendarData.push({
              month:this.updateData[0].month, 
              daysInMonth:this.updateData
            })
          }else{
            this.patchCreateData[indexPatchCreateData].calendarData[index].daysInMonth = this.updateData
          }
        }
      }
      
      Object.assign(this,{
        chooseDays:[],
        showMealChoose:false,
        removeClick: false
      })

      let activeTimeIndex = this.patchCreateData.findIndex(item => {
        return item.mealTimeId == this.activeTime
      })
      this.calendarOption = this.patchCreateData[activeTimeIndex];
    },

    // 添加菜品
    createMeal(){
      // 清除菜品搜索框
      this.searchMeal = '';
      // 恢复全部待选菜品
      this.meals = this.originMeals;
      // 清空已选菜品
      this.checkedMeals = [];
      // 移除点击事件
      this.removeClick = true;
      // 展示菜品供用户选择
      this.showMealChoose = true
    },

    // 查看所选日期菜品
    myCheckDetail(val){
      Object.assign(this,{
        checkDetailTitle:`计划在${val.dateStr}上架的菜品`,
        checkDetailMealArr:val.mealArr,
        checkDetailVisible:true
      })
    },

    // 移除菜品
    deleteMeal(index){
      this.checkDetailMealArr.splice(index,1)

      if(this.checkDetailMealArr.length == 0) this.checkDetailVisible = false
    },

    //上月
    preMonth(val){
      this.updateData = []
    },

    // 下月
    nextMonth(val){
      this.updateData = []
    },
    
    //取消操作
    cancelOpt(){
      this.chooseDays.map(item => {
        item.choose = false
      })
      Object.assign(this,{
        checkedMeals: [],
        chooseDays: [],
        showMealChoose: false,
        removeClick: false
      })
    },

    // 根据菜品名称本地搜索菜品
    onSearch(){
      let NewMeals = []; 
      this.originMeals.map((item) => {
        if (item.title.search(this.searchMeal) != -1){
          NewMeals.push(item);
        }
      })
      this.meals = NewMeals;
    },


    // 添加菜品弹窗的分页
    async changePage(){
      this.current += 1;
      await this.getDailyList()
    }
  },
  //生命周期 - 创建完成（可以访问当前this实例）
  created () {
    // this.getMenuType();
    this.getMealtimeList();
    this.getAllMenu();
    // this.getTableData();
  },
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted () { },
  beforeCreate () { }, //生命周期 - 创建之前
  beforeMount () { }, //生命周期 - 挂载之前
  beforeUpdate () { }, //生命周期 - 更新之前
  updated () { }, //生命周期 - 更新之后
  beforeDestroy () { }, //生命周期 - 销毁之前
  destroyed () { }, //生命周期 - 销毁完成
  activated () { }, //如果页面有keep-alive缓存功能，这个函数会触发
};
</script>
<style lang='less' scoped>
//@import url(); 引入公共css类
.mainBox {
  .control_box {
    background-color: white;
    padding: 20px;
    margin-bottom: 10px;
  }
  .show_left {
    background-color: white;
    width: 300px;
    padding: 0 10px;
    // position: absolute;
    float: left;
    height: calc(92vh - 150px);
    overflow: auto;
  }
  .show_right {
    background-color: white;
    padding: 0 10px;
    height: calc(92vh - 150px);
    width: calc(100% - 310px);
    overflow: auto;
    float: right;
  }
}
.mt_30{
  margin-top: 30px;
}

.mb_10{
  margin-bottom: 10px;
}

.text_limit{
  width: 110px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; //文本不换行，这样超出一行的部分被截取，显示...
}

.control-wrapper{
  margin: 0 auto;
  width: 500px;
  display: flex;
  .add_meal, .save_meal, .back-btn{
    color: white;
    background-color: #1294fe;
    text-align: center;
    border-radius: 10px;
    width: 80px;
    height: 20px;
    line-height: 20px;
    margin: 10px auto;
    cursor: pointer;
    -o-user-select: none;
    -moz-user-select: none; /*火狐 firefox*/
    -webkit-user-select: none; /*webkit浏览器*/
    -ms-user-select: none; /*IE10+*/
    -khtml-user-select :none; /*早期的浏览器*/
    user-select: none; 
  }

  .back-btn{
    color: #1294fe;
    background-color: white;
    border: 1px solid #1294fe;
  }
}


.meal_row{
  margin-bottom: 10px;

  .remove_meal{
    background-color:#ff4e4f;
    color: white;
  }

  .meal_title{
    height: 30px;
    line-height: 30px;
  }
}
.pe_none{//禁止点击事件的class
  pointer-events: none;
}

.meal-title-wrapper{
  display: flex;
  align-items: center;
  margin: 20px 0;
  h3{
    margin: 0 20px 0 0;
  }
}
.menu-slot-btn{
  color:#000;
}

.active{
  color:#FF833D;
}
 
.show_left ::v-deep.ant-table-tbody > tr > td{
  padding: 0;
  border-bottom: 1px solid #f0f2f5!important;
}
.active-menu-td{
  background:#f0f2f5;
  width: 100%;
  height: 100%;
  padding: 16px 20px;
  border-right: 2px solid #FF833D;
}
.menu-td{
  background:#fff;
  width: 100%;
  height: 100%;
  padding: 16px;
  cursor:pointer;
}
</style>